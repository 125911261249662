import * as React from "react";

import { Hotel } from "typings/microserviceModels/hotels-flex-module";
import { PriceA11y } from "src/components/flexComponents/Hotels/utils/A11y/priceA11yInterface";
import { PriceLockupGetRates } from "./PriceLockupGetRates";
import { PriceLockupDefault } from "./PriceLockupDefaults";
import { useHotelsContext } from "src/components/flexComponents/Hotels/components/HotelsContext";
import { PriceLockupAlign } from "components/shared/PriceLockup/typings";
import { EGDSTextProps } from "@egds/react-core/text";

interface PriceLockupProps {
  hotel: Hotel;
  hotelLink: string;
  align?: PriceLockupAlign;
  priceA11y?: PriceA11y;
  strikeThroughPrice?: string;
  hasGetRatesText?: boolean;
  textAlignment?: EGDSTextProps["align"];
}

export function validatePrice({ formattedPrice, hotelPrice }: Hotel) {
  return formattedPrice && hotelPrice && hotelPrice.value !== "0";
}

export const PriceLockup = (props: PriceLockupProps) => {
  const { model } = useHotelsContext();
  const isValidPrice = validatePrice(props.hotel);

  if ((!isValidPrice && !model.showGetPriceIfNoPrice) || !props.hasGetRatesText) {
    return null;
  }

  if (!isValidPrice && model.showGetPriceIfNoPrice) {
    return <PriceLockupGetRates hotelLink={props.hotelLink} textAlignment={props.textAlignment} />;
  }

  return <PriceLockupDefault {...props} />;
};

export default PriceLockup;

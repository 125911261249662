import { useLocalization } from "@shared-ui/localization-context";
import * as React from "react";
import { EGDSText, EGDSTextProps } from "@egds/react-core/text";
import { EGDSLink } from "@egds/react-core/link";

interface PriceLockupGetRatesProps {
  hotelLink: string;
  textAlignment?: EGDSTextProps["align"];
}

export const PriceLockupGetRates = ({ hotelLink, textAlignment }: PriceLockupGetRatesProps) => {
  const { formatText } = useLocalization();

  return (
    <EGDSText size={300} weight="regular" align={textAlignment}>
      <EGDSLink className="getRatesText">
        <a href={hotelLink}>{formatText("hotels.price.getRates")}</a>
      </EGDSLink>
    </EGDSText>
  );
};

export default PriceLockupGetRates;
